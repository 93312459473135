:root {
  --color-neutral-100: #fbfcfc;
  --color-neutral-200: #f8f9f9;
  --color-neutral-300: #f4f5f6;
  --color-neutral-400: #eef0f2;
  --color-neutral-500: #e3e6e9;
  --color-neutral-600: #d5dade;
  --color-neutral-700: #c8cdd3;
  --color-neutral-800: #adb5bc;
  --color-neutral-900: #969da4;
  --color-neutral-1000: #747b82;
  --color-neutral-1100: #5e6469;
  --color-neutral-1200: #3d4348;
  --color-neutral-1300: #282c30;
  --color-neutral-1400: #1e2124;
  --color-dark-grey: #747b82;
  --color-dark: #747b82;
  --color-brand-200: #ecfcfe;
  --color-brand-300: #daf8fd;
  --color-brand-900: #11bad5;
  --color-brand-1100: #0f798a;
  --color-brand-blue: #11bad5;
  --color-blue-100: #fbfdfe;
  --color-blue-200: #f0f7fa;
  --color-blue-300: #d6e9f0;
  --color-iceblue-200: #edfafc;
  --color-sky-blue: #f0f7fa;
  --color-blue-800: #176d8c;
  --color-alternative-blue: #176d8c;
  --color-blue-1100: #044258;
  --color-blue-1200: #033649;
  --color-dark-blue: #044258;
  --color-error: #ff5460;
  --color-coral-100: #fff5f5;
  --color-coral-200: #ffe6e7;
  --color-coral-400: #ffb3b5;
  --color-coral-500: #ff999c;
  --color-coral-600: #ff757a;
  --color-coral-700: #ff5460;
  --color-coral-800: #e63749;
  --color-coral-900: #c2293b;
  --color-coral-1000: #9e1f2e;
  --color-coral-1100: #7c131f;
  --color-orange-100: #fff9f5;
  --color-orange-500: #ffb885;
  --color-orange-600: #ff9b54;
  --color-orange-1000: #b84d00;
  --color-tangerine: #ff9b54;
  --color-orange-900: #e05e00;
  --color-pumpkin: #e05e00;
  --color-purple-800: #594bcb;
  --color-iris: #594bcb;
  --color-white: #fff;
  --color-black: #000;
  --color-theme-dark: #00131a;
  --w-nav: 202px;
  --h-header: 88px;
  --space-0: 0rem;
  --space-2: 0.125rem;
  --space-4: 0.25rem;
  --space-8: 0.5rem;
  --space-16: 1rem;
  --space-20: 1.25rem;
  --space-24: 1.5rem;
  --space-32: 2rem;
  --space-40: 2.5rem;
  --space-48: 3rem;
  --space-56: 3.5rem;
  --space-64: 4rem;
  --space-80: 5rem;
  --space-96: 6rem;
  --space-112: 7rem;
  --space-128: 8rem;
  --bg-body: #fff;
  --color-regular: var(--color-neutral-1100);
  --bg-container: transparent;
  --gap-main: 2rem;
  --radius-main: 1rem;
  --radius-img: 24px;
  --color-focus: var(--color-dark-blue);
  --color-link: var(--color-brand-blue);
  --color-separator: var(--color-neutral-600);
  --fs-md: 0.875rem;
  --fs-sm: 0.75rem;
  --z-content: 0;
  --z-tooltip: 25;
  --z-header: 50;
  --z-popup: 150;
  --transition-cta: none linear 200ms;
  --font-base: Hello Type Next, Arial, Helvetica, sans-serif;
  --form-color-label: var(--color-brand-blue);
  --form-color-field-text: var(--color-dark-blue);
  --form-color-border: var(--color-neutral-600);
  --form-color-border-edit: var(--color-brand-blue);
}

@media (max-width: 1023px) {
  :root {
    --h-header: 72px;
  }
}
@media print {
  :root {
    --h-header: 0;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

ul[role=list],
ol[role=list] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

:where(input, button, textarea, select) {
  font: inherit;
  color: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@media print {
  @page {
    size: A4 portrait;
    margin: 1cm 0.5cm;
  }
  body {
    width: auto;
    margin: 0;
    line-height: 1.5;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

a:focus-visible,
button:focus-visible,
div[tabindex]:focus-visible,
span[tabindex]:focus-visible,
p[tabindex]:focus-visible,
li[tabindex]:focus-visible {
  border-color: var(--color-focus) !important;
  outline: 0;
  border: 3px dashed !important;
}

@font-face {
  font-family: "Hello Type Next";
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hello-type-next-regular.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hello-type-next-regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Hello Type Next";
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hello-type-next-medium.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hello-type-next-medium.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "HB Pictos";
  src: url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hb-pictos.woff2") format("woff2"), url("/etc.clientlibs/bnpparibas-neo-hb-acquisition-site-front/clientlibs/clientlib-base/resources/fonts/hb-pictos.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 1rem;
}

body {
  font-family: "Hello Type Next", Arial, sans-serif;
  background-color: var(--bg-body);
  color: var(--color-regular);
}

.container {
  margin-inline: auto;
  max-width: 90rem;
  background-color: var(--bg-container);
}

ul,
ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4 {
  font-weight: 500;
  color: var(--color-title);
  margin-top: 0;
  letter-spacing: 0.3px;
  line-height: 1;
}

h1 {
  font-size: 2.15rem;
  font-weight: 500;
}

a {
  color: var(--color-link);
}

.grid-row {
  --columns: 12;
  --gap: 2rem;
  display: flex;
  margin-left: calc(-0.5 * var(--gap));
  margin-right: calc(-0.5 * var(--gap));
}
.grid-row > [class*=col-] {
  --width: auto;
  flex: 0 0 var(--width);
  padding-left: calc(0.5 * var(--gap));
  padding-right: calc(0.5 * var(--gap));
}
.grid-row > .col {
  flex: 1 0 0%;
}
.grid-row > .col-1 {
  --width: calc(1 / var(--columns) * 100%);
}
.grid-row > .col-2 {
  --width: calc(2 / var(--columns) * 100%);
}
.grid-row > .col-3 {
  --width: calc(3 / var(--columns) * 100%);
}
.grid-row > .col-4 {
  --width: calc(4 / var(--columns) * 100%);
}
.grid-row > .col-5 {
  --width: calc(5 / var(--columns) * 100%);
}
.grid-row > .col-6 {
  --width: calc(6 / var(--columns) * 100%);
}
.grid-row > .col-7 {
  --width: calc(7 / var(--columns) * 100%);
}
.grid-row > .col-8 {
  --width: calc(8 / var(--columns) * 100%);
}
.grid-row > .col-9 {
  --width: calc(9 / var(--columns) * 100%);
}
.grid-row > .col-10 {
  --width: calc(10 / var(--columns) * 100%);
}
.grid-row > .col-11 {
  --width: calc(11 / var(--columns) * 100%);
}
.grid-row > .col-12 {
  --width: calc(12 / var(--columns) * 100%);
}
@media (min-width: 480px) {
  .grid-row > .col-auto--xs {
    flex: 0 0 auto;
  }
  .grid-row > .col-full--xs {
    flex: 1 0 0%;
  }
  .grid-row > .col-1--xs {
    --width: calc(1 / var(--columns) * 100%);
  }
  .grid-row > .col-2--xs {
    --width: calc(2 / var(--columns) * 100%);
  }
  .grid-row > .col-3--xs {
    --width: calc(3 / var(--columns) * 100%);
  }
  .grid-row > .col-4--xs {
    --width: calc(4 / var(--columns) * 100%);
  }
  .grid-row > .col-5--xs {
    --width: calc(5 / var(--columns) * 100%);
  }
  .grid-row > .col-6--xs {
    --width: calc(6 / var(--columns) * 100%);
  }
  .grid-row > .col-7--xs {
    --width: calc(7 / var(--columns) * 100%);
  }
  .grid-row > .col-8--xs {
    --width: calc(8 / var(--columns) * 100%);
  }
  .grid-row > .col-9--xs {
    --width: calc(9 / var(--columns) * 100%);
  }
  .grid-row > .col-10--xs {
    --width: calc(10 / var(--columns) * 100%);
  }
  .grid-row > .col-11--xs {
    --width: calc(11 / var(--columns) * 100%);
  }
  .grid-row > .col-12--xs {
    --width: calc(12 / var(--columns) * 100%);
  }
}
@media (min-width: 768px) {
  .grid-row > .col-auto--s {
    flex: 0 0 auto;
  }
  .grid-row > .col-full--s {
    flex: 1 0 0%;
  }
  .grid-row > .col-1--s {
    --width: calc(1 / var(--columns) * 100%);
  }
  .grid-row > .col-2--s {
    --width: calc(2 / var(--columns) * 100%);
  }
  .grid-row > .col-3--s {
    --width: calc(3 / var(--columns) * 100%);
  }
  .grid-row > .col-4--s {
    --width: calc(4 / var(--columns) * 100%);
  }
  .grid-row > .col-5--s {
    --width: calc(5 / var(--columns) * 100%);
  }
  .grid-row > .col-6--s {
    --width: calc(6 / var(--columns) * 100%);
  }
  .grid-row > .col-7--s {
    --width: calc(7 / var(--columns) * 100%);
  }
  .grid-row > .col-8--s {
    --width: calc(8 / var(--columns) * 100%);
  }
  .grid-row > .col-9--s {
    --width: calc(9 / var(--columns) * 100%);
  }
  .grid-row > .col-10--s {
    --width: calc(10 / var(--columns) * 100%);
  }
  .grid-row > .col-11--s {
    --width: calc(11 / var(--columns) * 100%);
  }
  .grid-row > .col-12--s {
    --width: calc(12 / var(--columns) * 100%);
  }
}
@media (min-width: 1024px) {
  .grid-row > .col-auto--m {
    flex: 0 0 auto;
  }
  .grid-row > .col-full--m {
    flex: 1 0 0%;
  }
  .grid-row > .col-1--m {
    --width: calc(1 / var(--columns) * 100%);
  }
  .grid-row > .col-2--m {
    --width: calc(2 / var(--columns) * 100%);
  }
  .grid-row > .col-3--m {
    --width: calc(3 / var(--columns) * 100%);
  }
  .grid-row > .col-4--m {
    --width: calc(4 / var(--columns) * 100%);
  }
  .grid-row > .col-5--m {
    --width: calc(5 / var(--columns) * 100%);
  }
  .grid-row > .col-6--m {
    --width: calc(6 / var(--columns) * 100%);
  }
  .grid-row > .col-7--m {
    --width: calc(7 / var(--columns) * 100%);
  }
  .grid-row > .col-8--m {
    --width: calc(8 / var(--columns) * 100%);
  }
  .grid-row > .col-9--m {
    --width: calc(9 / var(--columns) * 100%);
  }
  .grid-row > .col-10--m {
    --width: calc(10 / var(--columns) * 100%);
  }
  .grid-row > .col-11--m {
    --width: calc(11 / var(--columns) * 100%);
  }
  .grid-row > .col-12--m {
    --width: calc(12 / var(--columns) * 100%);
  }
}
@media (min-width: 1280px) {
  .grid-row > .col-auto--l {
    flex: 0 0 auto;
  }
  .grid-row > .col-full--l {
    flex: 1 0 0%;
  }
  .grid-row > .col-1--l {
    --width: calc(1 / var(--columns) * 100%);
  }
  .grid-row > .col-2--l {
    --width: calc(2 / var(--columns) * 100%);
  }
  .grid-row > .col-3--l {
    --width: calc(3 / var(--columns) * 100%);
  }
  .grid-row > .col-4--l {
    --width: calc(4 / var(--columns) * 100%);
  }
  .grid-row > .col-5--l {
    --width: calc(5 / var(--columns) * 100%);
  }
  .grid-row > .col-6--l {
    --width: calc(6 / var(--columns) * 100%);
  }
  .grid-row > .col-7--l {
    --width: calc(7 / var(--columns) * 100%);
  }
  .grid-row > .col-8--l {
    --width: calc(8 / var(--columns) * 100%);
  }
  .grid-row > .col-9--l {
    --width: calc(9 / var(--columns) * 100%);
  }
  .grid-row > .col-10--l {
    --width: calc(10 / var(--columns) * 100%);
  }
  .grid-row > .col-11--l {
    --width: calc(11 / var(--columns) * 100%);
  }
  .grid-row > .col-12--l {
    --width: calc(12 / var(--columns) * 100%);
  }
}
@media (min-width: 1440px) {
  .grid-row > .col-auto--xl {
    flex: 0 0 auto;
  }
  .grid-row > .col-full--xl {
    flex: 1 0 0%;
  }
  .grid-row > .col-1--xl {
    --width: calc(1 / var(--columns) * 100%);
  }
  .grid-row > .col-2--xl {
    --width: calc(2 / var(--columns) * 100%);
  }
  .grid-row > .col-3--xl {
    --width: calc(3 / var(--columns) * 100%);
  }
  .grid-row > .col-4--xl {
    --width: calc(4 / var(--columns) * 100%);
  }
  .grid-row > .col-5--xl {
    --width: calc(5 / var(--columns) * 100%);
  }
  .grid-row > .col-6--xl {
    --width: calc(6 / var(--columns) * 100%);
  }
  .grid-row > .col-7--xl {
    --width: calc(7 / var(--columns) * 100%);
  }
  .grid-row > .col-8--xl {
    --width: calc(8 / var(--columns) * 100%);
  }
  .grid-row > .col-9--xl {
    --width: calc(9 / var(--columns) * 100%);
  }
  .grid-row > .col-10--xl {
    --width: calc(10 / var(--columns) * 100%);
  }
  .grid-row > .col-11--xl {
    --width: calc(11 / var(--columns) * 100%);
  }
  .grid-row > .col-12--xl {
    --width: calc(12 / var(--columns) * 100%);
  }
}

.text-neutral-100 {
  color: var(--color-neutral-100) !important;
}

.text-neutral-200 {
  color: var(--color-neutral-200) !important;
}

.text-neutral-300 {
  color: var(--color-neutral-300) !important;
}

.text-neutral-400 {
  color: var(--color-neutral-400) !important;
}

.text-neutral-500 {
  color: var(--color-neutral-500) !important;
}

.text-neutral-600 {
  color: var(--color-neutral-600) !important;
}

.text-neutral-700 {
  color: var(--color-neutral-700) !important;
}

.text-neutral-800 {
  color: var(--color-neutral-800) !important;
}

.text-neutral-900 {
  color: var(--color-neutral-900) !important;
}

.text-neutral-1000 {
  color: var(--color-neutral-1000) !important;
}

.text-neutral-1100 {
  color: var(--color-neutral-1100) !important;
}

.text-neutral-1200 {
  color: var(--color-neutral-1200) !important;
}

.text-neutral-1300 {
  color: var(--color-neutral-1300) !important;
}

.text-neutral-1400 {
  color: var(--color-neutral-1400) !important;
}

.text-dark-grey {
  color: var(--color-dark-grey) !important;
}

.text-dark {
  color: var(--color-dark) !important;
}

.text-brand-200 {
  color: var(--color-brand-200) !important;
}

.text-brand-300 {
  color: var(--color-brand-300) !important;
}

.text-brand-900 {
  color: var(--color-brand-900) !important;
}

.text-brand-1100 {
  color: var(--color-brand-1100) !important;
}

.text-brand-blue {
  color: var(--color-brand-blue) !important;
}

.text-blue-100 {
  color: var(--color-blue-100) !important;
}

.text-blue-200 {
  color: var(--color-blue-200) !important;
}

.text-blue-300 {
  color: var(--color-blue-300) !important;
}

.text-iceblue-200 {
  color: var(--color-iceblue-200) !important;
}

.text-sky-blue {
  color: var(--color-sky-blue) !important;
}

.text-blue-800 {
  color: var(--color-blue-800) !important;
}

.text-alternative-blue {
  color: var(--color-alternative-blue) !important;
}

.text-blue-1100 {
  color: var(--color-blue-1100) !important;
}

.text-blue-1200 {
  color: var(--color-blue-1200) !important;
}

.text-dark-blue {
  color: var(--color-dark-blue) !important;
}

.text-error {
  color: var(--color-error) !important;
}

.text-coral-100 {
  color: var(--color-coral-100) !important;
}

.text-coral-200 {
  color: var(--color-coral-200) !important;
}

.text-coral-400 {
  color: var(--color-coral-400) !important;
}

.text-coral-500 {
  color: var(--color-coral-500) !important;
}

.text-coral-600 {
  color: var(--color-coral-600) !important;
}

.text-coral-700 {
  color: var(--color-coral-700) !important;
}

.text-coral-800 {
  color: var(--color-coral-800) !important;
}

.text-coral-900 {
  color: var(--color-coral-900) !important;
}

.text-coral-1000 {
  color: var(--color-coral-1000) !important;
}

.text-coral-1100 {
  color: var(--color-coral-1100) !important;
}

.text-orange-100 {
  color: var(--color-orange-100) !important;
}

.text-orange-500 {
  color: var(--color-orange-500) !important;
}

.text-orange-600 {
  color: var(--color-orange-600) !important;
}

.text-orange-1000 {
  color: var(--color-orange-1000) !important;
}

.text-tangerine {
  color: var(--color-tangerine) !important;
}

.text-orange-900 {
  color: var(--color-orange-900) !important;
}

.text-pumpkin {
  color: var(--color-pumpkin) !important;
}

.text-purple-800 {
  color: var(--color-purple-800) !important;
}

.text-iris {
  color: var(--color-iris) !important;
}

.text-white {
  color: var(--color-white) !important;
}

.text-black {
  color: var(--color-black) !important;
}

.text-theme-dark {
  color: var(--color-theme-dark) !important;
}

.bg-neutral-100 {
  background-color: var(--color-neutral-100) !important;
}

.bg-neutral-200 {
  background-color: var(--color-neutral-200) !important;
}

.bg-neutral-300 {
  background-color: var(--color-neutral-300) !important;
}

.bg-neutral-400 {
  background-color: var(--color-neutral-400) !important;
}

.bg-neutral-500 {
  background-color: var(--color-neutral-500) !important;
}

.bg-neutral-600 {
  background-color: var(--color-neutral-600) !important;
}

.bg-neutral-700 {
  background-color: var(--color-neutral-700) !important;
}

.bg-neutral-800 {
  background-color: var(--color-neutral-800) !important;
}

.bg-neutral-900 {
  background-color: var(--color-neutral-900) !important;
}

.bg-neutral-1000 {
  background-color: var(--color-neutral-1000) !important;
}

.bg-neutral-1100 {
  background-color: var(--color-neutral-1100) !important;
}

.bg-neutral-1200 {
  background-color: var(--color-neutral-1200) !important;
}

.bg-neutral-1300 {
  background-color: var(--color-neutral-1300) !important;
}

.bg-neutral-1400 {
  background-color: var(--color-neutral-1400) !important;
}

.bg-dark-grey {
  background-color: var(--color-dark-grey) !important;
}

.bg-dark {
  background-color: var(--color-dark) !important;
}

.bg-brand-200 {
  background-color: var(--color-brand-200) !important;
}

.bg-brand-300 {
  background-color: var(--color-brand-300) !important;
}

.bg-brand-900 {
  background-color: var(--color-brand-900) !important;
}

.bg-brand-1100 {
  background-color: var(--color-brand-1100) !important;
}

.bg-brand-blue {
  background-color: var(--color-brand-blue) !important;
}

.bg-blue-100 {
  background-color: var(--color-blue-100) !important;
}

.bg-blue-200 {
  background-color: var(--color-blue-200) !important;
}

.bg-blue-300 {
  background-color: var(--color-blue-300) !important;
}

.bg-iceblue-200 {
  background-color: var(--color-iceblue-200) !important;
}

.bg-sky-blue {
  background-color: var(--color-sky-blue) !important;
}

.bg-blue-800 {
  background-color: var(--color-blue-800) !important;
}

.bg-alternative-blue {
  background-color: var(--color-alternative-blue) !important;
}

.bg-blue-1100 {
  background-color: var(--color-blue-1100) !important;
}

.bg-blue-1200 {
  background-color: var(--color-blue-1200) !important;
}

.bg-dark-blue {
  background-color: var(--color-dark-blue) !important;
}

.bg-error {
  background-color: var(--color-error) !important;
}

.bg-coral-100 {
  background-color: var(--color-coral-100) !important;
}

.bg-coral-200 {
  background-color: var(--color-coral-200) !important;
}

.bg-coral-400 {
  background-color: var(--color-coral-400) !important;
}

.bg-coral-500 {
  background-color: var(--color-coral-500) !important;
}

.bg-coral-600 {
  background-color: var(--color-coral-600) !important;
}

.bg-coral-700 {
  background-color: var(--color-coral-700) !important;
}

.bg-coral-800 {
  background-color: var(--color-coral-800) !important;
}

.bg-coral-900 {
  background-color: var(--color-coral-900) !important;
}

.bg-coral-1000 {
  background-color: var(--color-coral-1000) !important;
}

.bg-coral-1100 {
  background-color: var(--color-coral-1100) !important;
}

.bg-orange-100 {
  background-color: var(--color-orange-100) !important;
}

.bg-orange-500 {
  background-color: var(--color-orange-500) !important;
}

.bg-orange-600 {
  background-color: var(--color-orange-600) !important;
}

.bg-orange-1000 {
  background-color: var(--color-orange-1000) !important;
}

.bg-tangerine {
  background-color: var(--color-tangerine) !important;
}

.bg-orange-900 {
  background-color: var(--color-orange-900) !important;
}

.bg-pumpkin {
  background-color: var(--color-pumpkin) !important;
}

.bg-purple-800 {
  background-color: var(--color-purple-800) !important;
}

.bg-iris {
  background-color: var(--color-iris) !important;
}

.bg-white {
  background-color: var(--color-white) !important;
}

.bg-black {
  background-color: var(--color-black) !important;
}

.bg-theme-dark {
  background-color: var(--color-theme-dark) !important;
}

.m-0 {
  margin: 0rem;
}

.m-x-0 {
  margin-inline: 0rem;
}

.m-l-0 {
  margin-inline-start: 0rem;
}

.m-r-0 {
  margin-inline-end: 0rem;
}

.m-y-0 {
  margin-block: 0rem;
}

.m-t-0 {
  margin-block-start: 0rem;
}

.m-b-0 {
  margin-block-end: 0rem;
}

.m-2 {
  margin: 0.125rem;
}

.m-x-2 {
  margin-inline: 0.125rem;
}

.m-l-2 {
  margin-inline-start: 0.125rem;
}

.m-r-2 {
  margin-inline-end: 0.125rem;
}

.m-y-2 {
  margin-block: 0.125rem;
}

.m-t-2 {
  margin-block-start: 0.125rem;
}

.m-b-2 {
  margin-block-end: 0.125rem;
}

.m-4 {
  margin: 0.25rem;
}

.m-x-4 {
  margin-inline: 0.25rem;
}

.m-l-4 {
  margin-inline-start: 0.25rem;
}

.m-r-4 {
  margin-inline-end: 0.25rem;
}

.m-y-4 {
  margin-block: 0.25rem;
}

.m-t-4 {
  margin-block-start: 0.25rem;
}

.m-b-4 {
  margin-block-end: 0.25rem;
}

.m-8 {
  margin: 0.5rem;
}

.m-x-8 {
  margin-inline: 0.5rem;
}

.m-l-8 {
  margin-inline-start: 0.5rem;
}

.m-r-8 {
  margin-inline-end: 0.5rem;
}

.m-y-8 {
  margin-block: 0.5rem;
}

.m-t-8 {
  margin-block-start: 0.5rem;
}

.m-b-8 {
  margin-block-end: 0.5rem;
}

.m-16 {
  margin: 1rem;
}

.m-x-16 {
  margin-inline: 1rem;
}

.m-l-16 {
  margin-inline-start: 1rem;
}

.m-r-16 {
  margin-inline-end: 1rem;
}

.m-y-16 {
  margin-block: 1rem;
}

.m-t-16 {
  margin-block-start: 1rem;
}

.m-b-16 {
  margin-block-end: 1rem;
}

.m-20 {
  margin: 1.25rem;
}

.m-x-20 {
  margin-inline: 1.25rem;
}

.m-l-20 {
  margin-inline-start: 1.25rem;
}

.m-r-20 {
  margin-inline-end: 1.25rem;
}

.m-y-20 {
  margin-block: 1.25rem;
}

.m-t-20 {
  margin-block-start: 1.25rem;
}

.m-b-20 {
  margin-block-end: 1.25rem;
}

.m-24 {
  margin: 1.5rem;
}

.m-x-24 {
  margin-inline: 1.5rem;
}

.m-l-24 {
  margin-inline-start: 1.5rem;
}

.m-r-24 {
  margin-inline-end: 1.5rem;
}

.m-y-24 {
  margin-block: 1.5rem;
}

.m-t-24 {
  margin-block-start: 1.5rem;
}

.m-b-24 {
  margin-block-end: 1.5rem;
}

.m-32 {
  margin: 2rem;
}

.m-x-32 {
  margin-inline: 2rem;
}

.m-l-32 {
  margin-inline-start: 2rem;
}

.m-r-32 {
  margin-inline-end: 2rem;
}

.m-y-32 {
  margin-block: 2rem;
}

.m-t-32 {
  margin-block-start: 2rem;
}

.m-b-32 {
  margin-block-end: 2rem;
}

.m-40 {
  margin: 2.5rem;
}

.m-x-40 {
  margin-inline: 2.5rem;
}

.m-l-40 {
  margin-inline-start: 2.5rem;
}

.m-r-40 {
  margin-inline-end: 2.5rem;
}

.m-y-40 {
  margin-block: 2.5rem;
}

.m-t-40 {
  margin-block-start: 2.5rem;
}

.m-b-40 {
  margin-block-end: 2.5rem;
}

.m-48 {
  margin: 3rem;
}

.m-x-48 {
  margin-inline: 3rem;
}

.m-l-48 {
  margin-inline-start: 3rem;
}

.m-r-48 {
  margin-inline-end: 3rem;
}

.m-y-48 {
  margin-block: 3rem;
}

.m-t-48 {
  margin-block-start: 3rem;
}

.m-b-48 {
  margin-block-end: 3rem;
}

.m-56 {
  margin: 3.5rem;
}

.m-x-56 {
  margin-inline: 3.5rem;
}

.m-l-56 {
  margin-inline-start: 3.5rem;
}

.m-r-56 {
  margin-inline-end: 3.5rem;
}

.m-y-56 {
  margin-block: 3.5rem;
}

.m-t-56 {
  margin-block-start: 3.5rem;
}

.m-b-56 {
  margin-block-end: 3.5rem;
}

.m-64 {
  margin: 4rem;
}

.m-x-64 {
  margin-inline: 4rem;
}

.m-l-64 {
  margin-inline-start: 4rem;
}

.m-r-64 {
  margin-inline-end: 4rem;
}

.m-y-64 {
  margin-block: 4rem;
}

.m-t-64 {
  margin-block-start: 4rem;
}

.m-b-64 {
  margin-block-end: 4rem;
}

.m-80 {
  margin: 5rem;
}

.m-x-80 {
  margin-inline: 5rem;
}

.m-l-80 {
  margin-inline-start: 5rem;
}

.m-r-80 {
  margin-inline-end: 5rem;
}

.m-y-80 {
  margin-block: 5rem;
}

.m-t-80 {
  margin-block-start: 5rem;
}

.m-b-80 {
  margin-block-end: 5rem;
}

.m-96 {
  margin: 6rem;
}

.m-x-96 {
  margin-inline: 6rem;
}

.m-l-96 {
  margin-inline-start: 6rem;
}

.m-r-96 {
  margin-inline-end: 6rem;
}

.m-y-96 {
  margin-block: 6rem;
}

.m-t-96 {
  margin-block-start: 6rem;
}

.m-b-96 {
  margin-block-end: 6rem;
}

.m-112 {
  margin: 7rem;
}

.m-x-112 {
  margin-inline: 7rem;
}

.m-l-112 {
  margin-inline-start: 7rem;
}

.m-r-112 {
  margin-inline-end: 7rem;
}

.m-y-112 {
  margin-block: 7rem;
}

.m-t-112 {
  margin-block-start: 7rem;
}

.m-b-112 {
  margin-block-end: 7rem;
}

.m-128 {
  margin: 8rem;
}

.m-x-128 {
  margin-inline: 8rem;
}

.m-l-128 {
  margin-inline-start: 8rem;
}

.m-r-128 {
  margin-inline-end: 8rem;
}

.m-y-128 {
  margin-block: 8rem;
}

.m-t-128 {
  margin-block-start: 8rem;
}

.m-b-128 {
  margin-block-end: 8rem;
}

.p-0 {
  padding: 0rem;
}

.p-x-0 {
  padding-inline: 0rem;
}

.p-l-0 {
  padding-inline-start: 0rem;
}

.p-r-0 {
  padding-inline-end: 0rem;
}

.p-y-0 {
  padding-block: 0rem;
}

.p-t-0 {
  padding-block-start: 0rem;
}

.p-b-0 {
  padding-block-end: 0rem;
}

.p-2 {
  padding: 0.125rem;
}

.p-x-2 {
  padding-inline: 0.125rem;
}

.p-l-2 {
  padding-inline-start: 0.125rem;
}

.p-r-2 {
  padding-inline-end: 0.125rem;
}

.p-y-2 {
  padding-block: 0.125rem;
}

.p-t-2 {
  padding-block-start: 0.125rem;
}

.p-b-2 {
  padding-block-end: 0.125rem;
}

.p-4 {
  padding: 0.25rem;
}

.p-x-4 {
  padding-inline: 0.25rem;
}

.p-l-4 {
  padding-inline-start: 0.25rem;
}

.p-r-4 {
  padding-inline-end: 0.25rem;
}

.p-y-4 {
  padding-block: 0.25rem;
}

.p-t-4 {
  padding-block-start: 0.25rem;
}

.p-b-4 {
  padding-block-end: 0.25rem;
}

.p-8 {
  padding: 0.5rem;
}

.p-x-8 {
  padding-inline: 0.5rem;
}

.p-l-8 {
  padding-inline-start: 0.5rem;
}

.p-r-8 {
  padding-inline-end: 0.5rem;
}

.p-y-8 {
  padding-block: 0.5rem;
}

.p-t-8 {
  padding-block-start: 0.5rem;
}

.p-b-8 {
  padding-block-end: 0.5rem;
}

.p-16 {
  padding: 1rem;
}

.p-x-16 {
  padding-inline: 1rem;
}

.p-l-16 {
  padding-inline-start: 1rem;
}

.p-r-16 {
  padding-inline-end: 1rem;
}

.p-y-16 {
  padding-block: 1rem;
}

.p-t-16 {
  padding-block-start: 1rem;
}

.p-b-16 {
  padding-block-end: 1rem;
}

.p-20 {
  padding: 1.25rem;
}

.p-x-20 {
  padding-inline: 1.25rem;
}

.p-l-20 {
  padding-inline-start: 1.25rem;
}

.p-r-20 {
  padding-inline-end: 1.25rem;
}

.p-y-20 {
  padding-block: 1.25rem;
}

.p-t-20 {
  padding-block-start: 1.25rem;
}

.p-b-20 {
  padding-block-end: 1.25rem;
}

.p-24 {
  padding: 1.5rem;
}

.p-x-24 {
  padding-inline: 1.5rem;
}

.p-l-24 {
  padding-inline-start: 1.5rem;
}

.p-r-24 {
  padding-inline-end: 1.5rem;
}

.p-y-24 {
  padding-block: 1.5rem;
}

.p-t-24 {
  padding-block-start: 1.5rem;
}

.p-b-24 {
  padding-block-end: 1.5rem;
}

.p-32 {
  padding: 2rem;
}

.p-x-32 {
  padding-inline: 2rem;
}

.p-l-32 {
  padding-inline-start: 2rem;
}

.p-r-32 {
  padding-inline-end: 2rem;
}

.p-y-32 {
  padding-block: 2rem;
}

.p-t-32 {
  padding-block-start: 2rem;
}

.p-b-32 {
  padding-block-end: 2rem;
}

.p-40 {
  padding: 2.5rem;
}

.p-x-40 {
  padding-inline: 2.5rem;
}

.p-l-40 {
  padding-inline-start: 2.5rem;
}

.p-r-40 {
  padding-inline-end: 2.5rem;
}

.p-y-40 {
  padding-block: 2.5rem;
}

.p-t-40 {
  padding-block-start: 2.5rem;
}

.p-b-40 {
  padding-block-end: 2.5rem;
}

.p-48 {
  padding: 3rem;
}

.p-x-48 {
  padding-inline: 3rem;
}

.p-l-48 {
  padding-inline-start: 3rem;
}

.p-r-48 {
  padding-inline-end: 3rem;
}

.p-y-48 {
  padding-block: 3rem;
}

.p-t-48 {
  padding-block-start: 3rem;
}

.p-b-48 {
  padding-block-end: 3rem;
}

.p-56 {
  padding: 3.5rem;
}

.p-x-56 {
  padding-inline: 3.5rem;
}

.p-l-56 {
  padding-inline-start: 3.5rem;
}

.p-r-56 {
  padding-inline-end: 3.5rem;
}

.p-y-56 {
  padding-block: 3.5rem;
}

.p-t-56 {
  padding-block-start: 3.5rem;
}

.p-b-56 {
  padding-block-end: 3.5rem;
}

.p-64 {
  padding: 4rem;
}

.p-x-64 {
  padding-inline: 4rem;
}

.p-l-64 {
  padding-inline-start: 4rem;
}

.p-r-64 {
  padding-inline-end: 4rem;
}

.p-y-64 {
  padding-block: 4rem;
}

.p-t-64 {
  padding-block-start: 4rem;
}

.p-b-64 {
  padding-block-end: 4rem;
}

.p-80 {
  padding: 5rem;
}

.p-x-80 {
  padding-inline: 5rem;
}

.p-l-80 {
  padding-inline-start: 5rem;
}

.p-r-80 {
  padding-inline-end: 5rem;
}

.p-y-80 {
  padding-block: 5rem;
}

.p-t-80 {
  padding-block-start: 5rem;
}

.p-b-80 {
  padding-block-end: 5rem;
}

.p-96 {
  padding: 6rem;
}

.p-x-96 {
  padding-inline: 6rem;
}

.p-l-96 {
  padding-inline-start: 6rem;
}

.p-r-96 {
  padding-inline-end: 6rem;
}

.p-y-96 {
  padding-block: 6rem;
}

.p-t-96 {
  padding-block-start: 6rem;
}

.p-b-96 {
  padding-block-end: 6rem;
}

.p-112 {
  padding: 7rem;
}

.p-x-112 {
  padding-inline: 7rem;
}

.p-l-112 {
  padding-inline-start: 7rem;
}

.p-r-112 {
  padding-inline-end: 7rem;
}

.p-y-112 {
  padding-block: 7rem;
}

.p-t-112 {
  padding-block-start: 7rem;
}

.p-b-112 {
  padding-block-end: 7rem;
}

.p-128 {
  padding: 8rem;
}

.p-x-128 {
  padding-inline: 8rem;
}

.p-l-128 {
  padding-inline-start: 8rem;
}

.p-r-128 {
  padding-inline-end: 8rem;
}

.p-y-128 {
  padding-block: 8rem;
}

.p-t-128 {
  padding-block-start: 8rem;
}

.p-b-128 {
  padding-block-end: 8rem;
}

.gap-0 {
  gap: 0rem;
}

.gap-2 {
  gap: 0.125rem;
}

.gap-4 {
  gap: 0.25rem;
}

.gap-8 {
  gap: 0.5rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-24 {
  gap: 1.5rem;
}

.gap-32 {
  gap: 2rem;
}

.gap-40 {
  gap: 2.5rem;
}

.gap-48 {
  gap: 3rem;
}

.gap-56 {
  gap: 3.5rem;
}

.gap-64 {
  gap: 4rem;
}

.gap-80 {
  gap: 5rem;
}

.gap-96 {
  gap: 6rem;
}

.gap-112 {
  gap: 7rem;
}

.gap-128 {
  gap: 8rem;
}

.dev-outline > * {
  outline: 1px dashed #ef6161;
}

.stretched-link::before {
  content: "";
  position: absolute;
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.hide {
  display: none !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-justify-start {
  justify-content: flex-start !important;
}

.flex-justify-end {
  justify-content: flex-end !important;
}

.flex-justify-center {
  justify-content: center !important;
}

.flex-justify-space-between {
  justify-content: space-between !important;
}

.flex-justify-space-around {
  justify-content: space-around !important;
}

.flex-justify-space-evenly {
  justify-content: space-evenly !important;
}

.flex-align-start {
  align-items: flex-start !important;
}

.flex-align-end {
  align-items: flex-end !important;
}

.flex-align-center {
  align-items: center !important;
}

.flex-align-strech {
  align-items: strech !important;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-self-start {
  align-self: flex-start !important;
}

.flex-self-end {
  align-self: flex-end !important;
}

.flex-self-center {
  align-self: center !important;
}

.flex-self-stretch {
  align-self: stretch !important;
}

.flex-self-baseline {
  align-self: baseline !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-item-fluid {
  flex: 1 1 0px !important;
}

.flex-item-fluid-auto {
  flex: 1 1 auto !important;
}

.flex-item-noshrink {
  flex-shrink: 0 !important;
}

.flex-item-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-item-nogrow {
  flex-grow: 0 !important;
}

.flex-item-grow-1 {
  flex-grow: 1 !important;
}

.flex-item-noflex {
  flex: 0 !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.sticky {
  position: sticky !important;
}

.sticky-top {
  top: 0 !important;
  position: sticky !important;
}

.sticky-bottom {
  bottom: 0 !important;
  position: sticky !important;
}

.fixed {
  position: fixed !important;
}

.absolute-center {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.absolute-x {
  position: absolute !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.absolute-cover {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.top-right {
  top: 0 !important;
  right: 0 !important;
}

.top-left {
  top: 0 !important;
  left: 0 !important;
}

.bottom-right {
  bottom: 0 !important;
  right: 0 !important;
}

.bottom-left {
  bottom: 0 !important;
  left: 0 !important;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-block {
  display: inline-block !important;
}

.f-right {
  float: right !important;
}

.f-left {
  float: left !important;
}

.clear {
  clear: both !important;
}

.clear-left {
  clear: left !important;
}

.clear-right {
  clear: right !important;
}

.border-radius {
  border-radius: var(--radius-main) !important;
}

.border-radius-50 {
  border-radius: 50% !important;
}

.border-radius-100 {
  border-radius: 100% !important;
}

.text-decoration {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

@media screen and (min-width: 480px) {
  .hide--xs {
    display: none !important;
  }
  .hidden--xs {
    display: none !important;
  }
  .show--xs {
    display: block !important;
  }
  .d-flex--xs {
    display: flex !important;
  }
  .flex-wrap--xs {
    flex-wrap: wrap !important;
  }
  .d-inline-flex--xs {
    display: inline-flex !important;
  }
  .flex-direction-row--xs {
    flex-direction: row !important;
  }
  .flex-direction-row-reverse--xs {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column--xs {
    flex-direction: column !important;
  }
  .flex-direction-column-reverse--xs {
    flex-direction: column-reverse !important;
  }
  .flex-justify-start--xs {
    justify-content: flex-start !important;
  }
  .flex-justify-end--xs {
    justify-content: flex-end !important;
  }
  .flex-justify-center--xs {
    justify-content: center !important;
  }
  .flex-justify-space-between--xs {
    justify-content: space-between !important;
  }
  .flex-justify-space-around--xs {
    justify-content: space-around !important;
  }
  .flex-justify-space-evenly--xs {
    justify-content: space-evenly !important;
  }
  .flex-align-start--xs {
    align-items: flex-start !important;
  }
  .flex-align-end--xs {
    align-items: flex-end !important;
  }
  .flex-align-center--xs {
    align-items: center !important;
  }
  .flex-align-strech--xs {
    align-items: strech !important;
  }
  .flex-align-baseline--xs {
    align-items: baseline !important;
  }
  .flex-self-start--xs {
    align-self: flex-start !important;
  }
  .flex-self-end--xs {
    align-self: flex-end !important;
  }
  .flex-self-center--xs {
    align-self: center !important;
  }
  .flex-self-stretch--xs {
    align-self: stretch !important;
  }
  .flex-self-baseline--xs {
    align-self: baseline !important;
  }
  .flex-nowrap--xs {
    flex-wrap: nowrap !important;
  }
  .flex-item-fluid--xs {
    flex: 1 1 0px !important;
  }
  .flex-item-fluid-auto--xs {
    flex: 1 1 auto !important;
  }
  .flex-item-noshrink--xs {
    flex-shrink: 0 !important;
  }
  .flex-item-shrink-1--xs {
    flex-shrink: 1 !important;
  }
  .flex-item-nogrow--xs {
    flex-grow: 0 !important;
  }
  .flex-item-grow-1--xs {
    flex-grow: 1 !important;
  }
  .flex-item-noflex--xs {
    flex: 0 !important;
  }
  .text-center--xs {
    text-align: center !important;
  }
  .text-right--xs {
    text-align: right !important;
  }
  .text-left--xs {
    text-align: left !important;
  }
  .relative--xs {
    position: relative !important;
  }
  .absolute--xs {
    position: absolute !important;
  }
  .sticky--xs {
    position: sticky !important;
  }
  .sticky-top--xs {
    top: 0 !important;
    position: sticky !important;
  }
  .sticky-bottom--xs {
    bottom: 0 !important;
    position: sticky !important;
  }
  .fixed--xs {
    position: fixed !important;
  }
  .absolute-center--xs {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .absolute-x--xs {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .absolute-cover--xs {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .top-right--xs {
    top: 0 !important;
    right: 0 !important;
  }
  .top-left--xs {
    top: 0 !important;
    left: 0 !important;
  }
  .bottom-right--xs {
    bottom: 0 !important;
    right: 0 !important;
  }
  .bottom-left--xs {
    bottom: 0 !important;
    left: 0 !important;
  }
  .d-block--xs {
    display: block !important;
  }
  .d-inline--xs {
    display: inline !important;
  }
  .d-grid--xs {
    display: grid !important;
  }
  .d-inline-block--xs {
    display: inline-block !important;
  }
  .f-right--xs {
    float: right !important;
  }
  .f-left--xs {
    float: left !important;
  }
  .clear--xs {
    clear: both !important;
  }
  .clear-left--xs {
    clear: left !important;
  }
  .clear-right--xs {
    clear: right !important;
  }
  .border-radius--xs {
    border-radius: var(--radius-main) !important;
  }
  .border-radius-50--xs {
    border-radius: 50% !important;
  }
  .border-radius-100--xs {
    border-radius: 100% !important;
  }
  .text-decoration--xs {
    text-decoration: underline !important;
  }
  .text-decoration-none--xs {
    text-decoration: none !important;
  }
}
@media screen and (min-width: 768px) {
  .hide--s {
    display: none !important;
  }
  .hidden--s {
    display: none !important;
  }
  .show--s {
    display: block !important;
  }
  .d-flex--s {
    display: flex !important;
  }
  .flex-wrap--s {
    flex-wrap: wrap !important;
  }
  .d-inline-flex--s {
    display: inline-flex !important;
  }
  .flex-direction-row--s {
    flex-direction: row !important;
  }
  .flex-direction-row-reverse--s {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column--s {
    flex-direction: column !important;
  }
  .flex-direction-column-reverse--s {
    flex-direction: column-reverse !important;
  }
  .flex-justify-start--s {
    justify-content: flex-start !important;
  }
  .flex-justify-end--s {
    justify-content: flex-end !important;
  }
  .flex-justify-center--s {
    justify-content: center !important;
  }
  .flex-justify-space-between--s {
    justify-content: space-between !important;
  }
  .flex-justify-space-around--s {
    justify-content: space-around !important;
  }
  .flex-justify-space-evenly--s {
    justify-content: space-evenly !important;
  }
  .flex-align-start--s {
    align-items: flex-start !important;
  }
  .flex-align-end--s {
    align-items: flex-end !important;
  }
  .flex-align-center--s {
    align-items: center !important;
  }
  .flex-align-strech--s {
    align-items: strech !important;
  }
  .flex-align-baseline--s {
    align-items: baseline !important;
  }
  .flex-self-start--s {
    align-self: flex-start !important;
  }
  .flex-self-end--s {
    align-self: flex-end !important;
  }
  .flex-self-center--s {
    align-self: center !important;
  }
  .flex-self-stretch--s {
    align-self: stretch !important;
  }
  .flex-self-baseline--s {
    align-self: baseline !important;
  }
  .flex-nowrap--s {
    flex-wrap: nowrap !important;
  }
  .flex-item-fluid--s {
    flex: 1 1 0px !important;
  }
  .flex-item-fluid-auto--s {
    flex: 1 1 auto !important;
  }
  .flex-item-noshrink--s {
    flex-shrink: 0 !important;
  }
  .flex-item-shrink-1--s {
    flex-shrink: 1 !important;
  }
  .flex-item-nogrow--s {
    flex-grow: 0 !important;
  }
  .flex-item-grow-1--s {
    flex-grow: 1 !important;
  }
  .flex-item-noflex--s {
    flex: 0 !important;
  }
  .text-center--s {
    text-align: center !important;
  }
  .text-right--s {
    text-align: right !important;
  }
  .text-left--s {
    text-align: left !important;
  }
  .relative--s {
    position: relative !important;
  }
  .absolute--s {
    position: absolute !important;
  }
  .sticky--s {
    position: sticky !important;
  }
  .sticky-top--s {
    top: 0 !important;
    position: sticky !important;
  }
  .sticky-bottom--s {
    bottom: 0 !important;
    position: sticky !important;
  }
  .fixed--s {
    position: fixed !important;
  }
  .absolute-center--s {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .absolute-x--s {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .absolute-cover--s {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .top-right--s {
    top: 0 !important;
    right: 0 !important;
  }
  .top-left--s {
    top: 0 !important;
    left: 0 !important;
  }
  .bottom-right--s {
    bottom: 0 !important;
    right: 0 !important;
  }
  .bottom-left--s {
    bottom: 0 !important;
    left: 0 !important;
  }
  .d-block--s {
    display: block !important;
  }
  .d-inline--s {
    display: inline !important;
  }
  .d-grid--s {
    display: grid !important;
  }
  .d-inline-block--s {
    display: inline-block !important;
  }
  .f-right--s {
    float: right !important;
  }
  .f-left--s {
    float: left !important;
  }
  .clear--s {
    clear: both !important;
  }
  .clear-left--s {
    clear: left !important;
  }
  .clear-right--s {
    clear: right !important;
  }
  .border-radius--s {
    border-radius: var(--radius-main) !important;
  }
  .border-radius-50--s {
    border-radius: 50% !important;
  }
  .border-radius-100--s {
    border-radius: 100% !important;
  }
  .text-decoration--s {
    text-decoration: underline !important;
  }
  .text-decoration-none--s {
    text-decoration: none !important;
  }
}
@media screen and (min-width: 1024px) {
  .hide--m {
    display: none !important;
  }
  .hidden--m {
    display: none !important;
  }
  .show--m {
    display: block !important;
  }
  .d-flex--m {
    display: flex !important;
  }
  .flex-wrap--m {
    flex-wrap: wrap !important;
  }
  .d-inline-flex--m {
    display: inline-flex !important;
  }
  .flex-direction-row--m {
    flex-direction: row !important;
  }
  .flex-direction-row-reverse--m {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column--m {
    flex-direction: column !important;
  }
  .flex-direction-column-reverse--m {
    flex-direction: column-reverse !important;
  }
  .flex-justify-start--m {
    justify-content: flex-start !important;
  }
  .flex-justify-end--m {
    justify-content: flex-end !important;
  }
  .flex-justify-center--m {
    justify-content: center !important;
  }
  .flex-justify-space-between--m {
    justify-content: space-between !important;
  }
  .flex-justify-space-around--m {
    justify-content: space-around !important;
  }
  .flex-justify-space-evenly--m {
    justify-content: space-evenly !important;
  }
  .flex-align-start--m {
    align-items: flex-start !important;
  }
  .flex-align-end--m {
    align-items: flex-end !important;
  }
  .flex-align-center--m {
    align-items: center !important;
  }
  .flex-align-strech--m {
    align-items: strech !important;
  }
  .flex-align-baseline--m {
    align-items: baseline !important;
  }
  .flex-self-start--m {
    align-self: flex-start !important;
  }
  .flex-self-end--m {
    align-self: flex-end !important;
  }
  .flex-self-center--m {
    align-self: center !important;
  }
  .flex-self-stretch--m {
    align-self: stretch !important;
  }
  .flex-self-baseline--m {
    align-self: baseline !important;
  }
  .flex-nowrap--m {
    flex-wrap: nowrap !important;
  }
  .flex-item-fluid--m {
    flex: 1 1 0px !important;
  }
  .flex-item-fluid-auto--m {
    flex: 1 1 auto !important;
  }
  .flex-item-noshrink--m {
    flex-shrink: 0 !important;
  }
  .flex-item-shrink-1--m {
    flex-shrink: 1 !important;
  }
  .flex-item-nogrow--m {
    flex-grow: 0 !important;
  }
  .flex-item-grow-1--m {
    flex-grow: 1 !important;
  }
  .flex-item-noflex--m {
    flex: 0 !important;
  }
  .text-center--m {
    text-align: center !important;
  }
  .text-right--m {
    text-align: right !important;
  }
  .text-left--m {
    text-align: left !important;
  }
  .relative--m {
    position: relative !important;
  }
  .absolute--m {
    position: absolute !important;
  }
  .sticky--m {
    position: sticky !important;
  }
  .sticky-top--m {
    top: 0 !important;
    position: sticky !important;
  }
  .sticky-bottom--m {
    bottom: 0 !important;
    position: sticky !important;
  }
  .fixed--m {
    position: fixed !important;
  }
  .absolute-center--m {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .absolute-x--m {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .absolute-cover--m {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .top-right--m {
    top: 0 !important;
    right: 0 !important;
  }
  .top-left--m {
    top: 0 !important;
    left: 0 !important;
  }
  .bottom-right--m {
    bottom: 0 !important;
    right: 0 !important;
  }
  .bottom-left--m {
    bottom: 0 !important;
    left: 0 !important;
  }
  .d-block--m {
    display: block !important;
  }
  .d-inline--m {
    display: inline !important;
  }
  .d-grid--m {
    display: grid !important;
  }
  .d-inline-block--m {
    display: inline-block !important;
  }
  .f-right--m {
    float: right !important;
  }
  .f-left--m {
    float: left !important;
  }
  .clear--m {
    clear: both !important;
  }
  .clear-left--m {
    clear: left !important;
  }
  .clear-right--m {
    clear: right !important;
  }
  .border-radius--m {
    border-radius: var(--radius-main) !important;
  }
  .border-radius-50--m {
    border-radius: 50% !important;
  }
  .border-radius-100--m {
    border-radius: 100% !important;
  }
  .text-decoration--m {
    text-decoration: underline !important;
  }
  .text-decoration-none--m {
    text-decoration: none !important;
  }
}
@media screen and (min-width: 1280px) {
  .hide--l {
    display: none !important;
  }
  .hidden--l {
    display: none !important;
  }
  .show--l {
    display: block !important;
  }
  .d-flex--l {
    display: flex !important;
  }
  .flex-wrap--l {
    flex-wrap: wrap !important;
  }
  .d-inline-flex--l {
    display: inline-flex !important;
  }
  .flex-direction-row--l {
    flex-direction: row !important;
  }
  .flex-direction-row-reverse--l {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column--l {
    flex-direction: column !important;
  }
  .flex-direction-column-reverse--l {
    flex-direction: column-reverse !important;
  }
  .flex-justify-start--l {
    justify-content: flex-start !important;
  }
  .flex-justify-end--l {
    justify-content: flex-end !important;
  }
  .flex-justify-center--l {
    justify-content: center !important;
  }
  .flex-justify-space-between--l {
    justify-content: space-between !important;
  }
  .flex-justify-space-around--l {
    justify-content: space-around !important;
  }
  .flex-justify-space-evenly--l {
    justify-content: space-evenly !important;
  }
  .flex-align-start--l {
    align-items: flex-start !important;
  }
  .flex-align-end--l {
    align-items: flex-end !important;
  }
  .flex-align-center--l {
    align-items: center !important;
  }
  .flex-align-strech--l {
    align-items: strech !important;
  }
  .flex-align-baseline--l {
    align-items: baseline !important;
  }
  .flex-self-start--l {
    align-self: flex-start !important;
  }
  .flex-self-end--l {
    align-self: flex-end !important;
  }
  .flex-self-center--l {
    align-self: center !important;
  }
  .flex-self-stretch--l {
    align-self: stretch !important;
  }
  .flex-self-baseline--l {
    align-self: baseline !important;
  }
  .flex-nowrap--l {
    flex-wrap: nowrap !important;
  }
  .flex-item-fluid--l {
    flex: 1 1 0px !important;
  }
  .flex-item-fluid-auto--l {
    flex: 1 1 auto !important;
  }
  .flex-item-noshrink--l {
    flex-shrink: 0 !important;
  }
  .flex-item-shrink-1--l {
    flex-shrink: 1 !important;
  }
  .flex-item-nogrow--l {
    flex-grow: 0 !important;
  }
  .flex-item-grow-1--l {
    flex-grow: 1 !important;
  }
  .flex-item-noflex--l {
    flex: 0 !important;
  }
  .text-center--l {
    text-align: center !important;
  }
  .text-right--l {
    text-align: right !important;
  }
  .text-left--l {
    text-align: left !important;
  }
  .relative--l {
    position: relative !important;
  }
  .absolute--l {
    position: absolute !important;
  }
  .sticky--l {
    position: sticky !important;
  }
  .sticky-top--l {
    top: 0 !important;
    position: sticky !important;
  }
  .sticky-bottom--l {
    bottom: 0 !important;
    position: sticky !important;
  }
  .fixed--l {
    position: fixed !important;
  }
  .absolute-center--l {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .absolute-x--l {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .absolute-cover--l {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .top-right--l {
    top: 0 !important;
    right: 0 !important;
  }
  .top-left--l {
    top: 0 !important;
    left: 0 !important;
  }
  .bottom-right--l {
    bottom: 0 !important;
    right: 0 !important;
  }
  .bottom-left--l {
    bottom: 0 !important;
    left: 0 !important;
  }
  .d-block--l {
    display: block !important;
  }
  .d-inline--l {
    display: inline !important;
  }
  .d-grid--l {
    display: grid !important;
  }
  .d-inline-block--l {
    display: inline-block !important;
  }
  .f-right--l {
    float: right !important;
  }
  .f-left--l {
    float: left !important;
  }
  .clear--l {
    clear: both !important;
  }
  .clear-left--l {
    clear: left !important;
  }
  .clear-right--l {
    clear: right !important;
  }
  .border-radius--l {
    border-radius: var(--radius-main) !important;
  }
  .border-radius-50--l {
    border-radius: 50% !important;
  }
  .border-radius-100--l {
    border-radius: 100% !important;
  }
  .text-decoration--l {
    text-decoration: underline !important;
  }
  .text-decoration-none--l {
    text-decoration: none !important;
  }
}
@media screen and (min-width: 1440px) {
  .hide--xl {
    display: none !important;
  }
  .hidden--xl {
    display: none !important;
  }
  .show--xl {
    display: block !important;
  }
  .d-flex--xl {
    display: flex !important;
  }
  .flex-wrap--xl {
    flex-wrap: wrap !important;
  }
  .d-inline-flex--xl {
    display: inline-flex !important;
  }
  .flex-direction-row--xl {
    flex-direction: row !important;
  }
  .flex-direction-row-reverse--xl {
    flex-direction: row-reverse !important;
  }
  .flex-direction-column--xl {
    flex-direction: column !important;
  }
  .flex-direction-column-reverse--xl {
    flex-direction: column-reverse !important;
  }
  .flex-justify-start--xl {
    justify-content: flex-start !important;
  }
  .flex-justify-end--xl {
    justify-content: flex-end !important;
  }
  .flex-justify-center--xl {
    justify-content: center !important;
  }
  .flex-justify-space-between--xl {
    justify-content: space-between !important;
  }
  .flex-justify-space-around--xl {
    justify-content: space-around !important;
  }
  .flex-justify-space-evenly--xl {
    justify-content: space-evenly !important;
  }
  .flex-align-start--xl {
    align-items: flex-start !important;
  }
  .flex-align-end--xl {
    align-items: flex-end !important;
  }
  .flex-align-center--xl {
    align-items: center !important;
  }
  .flex-align-strech--xl {
    align-items: strech !important;
  }
  .flex-align-baseline--xl {
    align-items: baseline !important;
  }
  .flex-self-start--xl {
    align-self: flex-start !important;
  }
  .flex-self-end--xl {
    align-self: flex-end !important;
  }
  .flex-self-center--xl {
    align-self: center !important;
  }
  .flex-self-stretch--xl {
    align-self: stretch !important;
  }
  .flex-self-baseline--xl {
    align-self: baseline !important;
  }
  .flex-nowrap--xl {
    flex-wrap: nowrap !important;
  }
  .flex-item-fluid--xl {
    flex: 1 1 0px !important;
  }
  .flex-item-fluid-auto--xl {
    flex: 1 1 auto !important;
  }
  .flex-item-noshrink--xl {
    flex-shrink: 0 !important;
  }
  .flex-item-shrink-1--xl {
    flex-shrink: 1 !important;
  }
  .flex-item-nogrow--xl {
    flex-grow: 0 !important;
  }
  .flex-item-grow-1--xl {
    flex-grow: 1 !important;
  }
  .flex-item-noflex--xl {
    flex: 0 !important;
  }
  .text-center--xl {
    text-align: center !important;
  }
  .text-right--xl {
    text-align: right !important;
  }
  .text-left--xl {
    text-align: left !important;
  }
  .relative--xl {
    position: relative !important;
  }
  .absolute--xl {
    position: absolute !important;
  }
  .sticky--xl {
    position: sticky !important;
  }
  .sticky-top--xl {
    top: 0 !important;
    position: sticky !important;
  }
  .sticky-bottom--xl {
    bottom: 0 !important;
    position: sticky !important;
  }
  .fixed--xl {
    position: fixed !important;
  }
  .absolute-center--xl {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
  .absolute-x--xl {
    position: absolute !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .absolute-cover--xl {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }
  .top-right--xl {
    top: 0 !important;
    right: 0 !important;
  }
  .top-left--xl {
    top: 0 !important;
    left: 0 !important;
  }
  .bottom-right--xl {
    bottom: 0 !important;
    right: 0 !important;
  }
  .bottom-left--xl {
    bottom: 0 !important;
    left: 0 !important;
  }
  .d-block--xl {
    display: block !important;
  }
  .d-inline--xl {
    display: inline !important;
  }
  .d-grid--xl {
    display: grid !important;
  }
  .d-inline-block--xl {
    display: inline-block !important;
  }
  .f-right--xl {
    float: right !important;
  }
  .f-left--xl {
    float: left !important;
  }
  .clear--xl {
    clear: both !important;
  }
  .clear-left--xl {
    clear: left !important;
  }
  .clear-right--xl {
    clear: right !important;
  }
  .border-radius--xl {
    border-radius: var(--radius-main) !important;
  }
  .border-radius-50--xl {
    border-radius: 50% !important;
  }
  .border-radius-100--xl {
    border-radius: 100% !important;
  }
  .text-decoration--xl {
    text-decoration: underline !important;
  }
  .text-decoration-none--xl {
    text-decoration: none !important;
  }
}
.dev-outline--layout .grid-row > * {
  outline: 1px dashed var(--color-coral-700);
}

i[class^=picto-],
i[class*=" picto-"] {
  display: inline-flex;
  font-size: 24px;
}
i[class^=picto-]::before,
i[class*=" picto-"]::before {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
}

i[class^=picto-48-],
i[class*=" picto-48-"] {
  font-size: 48px;
}

i.picto--16 {
  font-size: 16px;
}
i.picto--20 {
  font-size: 20px;
}
i.picto--24 {
  font-size: 24px;
}
i.picto--32 {
  font-size: 32px;
}
i.picto--40 {
  font-size: 40px;
}
i.picto--48 {
  font-size: 48px;
}
i.picto--88 {
  font-size: 88px;
}

.picto,
.iconSvg {
  display: inline-flex;
}

.picto-48-card::before {
  content: "\f101";
}

.picto-48-check-plain::before {
  content: "\f102";
}

.picto-48-check::before {
  content: "\f103";
}

.picto-48-delete::before {
  content: "\f104";
}

.picto-48-lock::before {
  content: "\f105";
}

.picto-48-maintenance::before {
  content: "\f106";
}

.picto-48-mobile::before {
  content: "\f107";
}

.picto-48-statistic::before {
  content: "\f108";
}

.picto-48-transfer-international::before {
  content: "\f109";
}

.picto-48-transfer::before {
  content: "\f10a";
}

.picto-48-umbrella::before {
  content: "\f10b";
}

.picto-action-delete::before {
  content: "\f10d";
}

.picto-action-download::before {
  content: "\f10e";
}

.picto-action-edit::before {
  content: "\f10f";
}

.picto-action-manage-account::before {
  content: "\f110";
}

.picto-action-more::before {
  content: "\f111";
}

.picto-action-pin::before {
  content: "\f112";
}

.picto-action-remove-simple::before {
  content: "\f113";
}

.picto-action-search::before {
  content: "\f114";
}

.picto-bank-and-finance-bank::before {
  content: "\f115";
}

.picto-bank-and-finance-card-card-opposition::before {
  content: "\f116";
}

.picto-bank-and-finance-card-card::before {
  content: "\f117";
}

.picto-bank-and-finance-graph::before {
  content: "\f118";
}

.picto-bank-and-finance-savings::before {
  content: "\f119";
}

.picto-bank-and-finance-statistic::before {
  content: "\f11a";
}

.picto-bulding-house::before {
  content: "\f11b";
}

.picto-chevron-down::before {
  content: "\f11c";
}

.picto-chevron-left::before {
  content: "\f11d";
}

.picto-chevron-right::before {
  content: "\f11e";
}

.picto-chevron-up::before {
  content: "\f11f";
}

.picto-hb-products-livret-hello::before {
  content: "\f120";
}

.picto-object-bulb::before {
  content: "\f121";
}

.picto-security-shield::before {
  content: "\f122";
}

.picto-setting-filter::before {
  content: "\f123";
}

.picto-setting-parameters::before {
  content: "\f124";
}

.picto-state-information::before {
  content: "\f125";
}

.picto-state-success::before {
  content: "\f126";
}

.picto-time-calendar::before {
  content: "\f127";
}

.picto-state-help-fill::before {
  content: "\f128";
}

.picto-action-add-stroke::before {
  content: "\f10c";
}

.picto-state-error::before {
  content: "\f129";
}

.picto-state-warning::before {
  content: "\f12a";
}

.picto-arrow-left::before {
  content: "\f12b";
}

.picto-arrow-right::before {
  content: "\f12c";
}

.picto-bank-and-finance-mobility::before {
  content: "\f12d";
}

.picto-document-search::before {
  content: "\f12e";
}

.picto-insurance-sinister::before {
  content: "\f12f";
}

.picto-time-clock::before {
  content: "\f130";
}

.picto-device-mobile-default::before {
  content: "\f131";
}

.picto-48-avatar-beneficiary::before {
  content: "\f132";
}

.picto-document-document::before {
  content: "\f133";
}

.picto-person-profile-default::before {
  content: "\f134";
}

.picto-48-warning::before {
  content: "\f135";
}

.picto-state-exclamation-mark::before {
  content: "\f136";
}

.picto-remboursement-frais-sante::before {
  content: "\f138";
}

.picto-demande-prise-en-charge::before {
  content: "\f139";
}

.picto-tous-mes-services::before {
  content: "\f13a";
}

.picto-eye-open::before {
  content: "\f13b";
}

.picto-buoy::before {
  content: "\f13c";
}

.picto-doc-search::before {
  content: "\f13d";
}

.picto-48-envelop::before {
  content: "\f13f";
}

.picto-party::before {
  content: "\f140";
}

.picto-48-phone-call::before {
  content: "\f137";
}

.picto-48-travel-bag::before {
  content: "\f13e";
}

.picto-action-add-fill::before {
  content: "\f141";
}

.picto-action-add-outline::before {
  content: "\f142";
}

.picto-action-add::before {
  content: "\f143";
}

.picto-action-burger::before {
  content: "\f144";
}

.picto-action-categorize::before {
  content: "\f145";
}

.picto-action-close-large::before {
  content: "\f146";
}

.picto-action-close-small::before {
  content: "\f147";
}

.picto-action-copy::before {
  content: "\f148";
}

.picto-action-delete-fill::before {
  content: "\f149";
}

.picto-action-delete-outline::before {
  content: "\f14a";
}

.picto-action-disconnect::before {
  content: "\f14b";
}

.picto-action-drag::before {
  content: "\f14c";
}

.picto-action-external-website::before {
  content: "\f14d";
}

.picto-action-import::before {
  content: "\f14e";
}

.picto-action-minus-fill::before {
  content: "\f14f";
}

.picto-action-scan::before {
  content: "\f150";
}

.picto-arrow-chevron-default-down-large::before {
  content: "\f151";
}

.picto-arrow-chevron-default-down-small::before {
  content: "\f152";
}

.picto-arrow-chevron-default-left-large::before {
  content: "\f153";
}

.picto-arrow-chevron-default-left-small::before {
  content: "\f154";
}

.picto-arrow-chevron-default-right-large::before {
  content: "\f155";
}

.picto-arrow-chevron-default-right-small::before {
  content: "\f156";
}

.picto-arrow-chevron-default-up-large::before {
  content: "\f157";
}

.picto-arrow-chevron-default-up-small::before {
  content: "\f158";
}

.picto-bank-and-finance-card-carte-virtuelle::before {
  content: "\f159";
}

.picto-bank-and-finance-card-hello-one-duo::before {
  content: "\f15a";
}

.picto-bank-and-finance-card-hello-prime-duo::before {
  content: "\f15b";
}

.picto-bank-and-finance-card-hello-prime-virtuelle::before {
  content: "\f15c";
}

.picto-bank-and-finance-card-hello-prime::before {
  content: "\f15d";
}

.picto-bank-and-finance-card-opposition-alternative::before {
  content: "\f15e";
}

.picto-bank-and-finance-card-opposition-default::before {
  content: "\f15f";
}

.picto-bank-and-finance-card-origin::before {
  content: "\f160";
}

.picto-bank-and-finance-card-timer::before {
  content: "\f161";
}

.picto-bank-and-finance-check-issued::before {
  content: "\f162";
}

.picto-bank-and-finance-check-received::before {
  content: "\f163";
}

.picto-bank-and-finance-check::before {
  content: "\f164";
}

.picto-bank-and-finance-coin::before {
  content: "\f165";
}

.picto-bank-and-finance-credit-car::before {
  content: "\f166";
}

.picto-bank-and-finance-dividends::before {
  content: "\f167";
}

.picto-bank-and-finance-list::before {
  content: "\f168";
}

.picto-bank-and-finance-money-deposit::before {
  content: "\f169";
}

.picto-bank-and-finance-money-withdrawal::before {
  content: "\f16a";
}

.picto-bank-and-finance-pile-of-coins::before {
  content: "\f16b";
}

.picto-bank-and-finance-refund::before {
  content: "\f16c";
}

.picto-bank-and-finance-revolving-credit-alternative::before {
  content: "\f16d";
}

.picto-bank-and-finance-revolving-credit-default::before {
  content: "\f16e";
}

.picto-bank-and-finance-switch-offer::before {
  content: "\f16f";
}

.picto-bank-and-finance-transfer-international::before {
  content: "\f170";
}

.picto-bank-and-finance-transfer-issued::before {
  content: "\f171";
}

.picto-bank-and-finance-transfer-received::before {
  content: "\f172";
}

.picto-bank-and-finance-transfer-transfer::before {
  content: "\f173";
}

.picto-bank-and-finance-wallet::before {
  content: "\f174";
}

.picto-device-print::before {
  content: "\f175";
}

.picto-shape-graph::before {
  content: "\f176";
}

.picto-state-success-default::before {
  content: "\f177";
}

.picto-building-agency::before {
  content: "\f178";
}

.picto-building-building-euro::before {
  content: "\f179";
}

.picto-building-hospital::before {
  content: "\f17a";
}

.picto-building-house-and-building::before {
  content: "\f17b";
}

.picto-building-house-euro::before {
  content: "\f17c";
}

.picto-building-house::before {
  content: "\f17d";
}

.picto-building-store::before {
  content: "\f17e";
}

.picto-childhood-backpack::before {
  content: "\f17f";
}

.picto-contact-chat::before {
  content: "\f180";
}

.picto-contact-envelop::before {
  content: "\f181";
}

.picto-contact-headphone::before {
  content: "\f182";
}

.picto-device-calculator::before {
  content: "\f183";
}

.picto-device-internet-box::before {
  content: "\f184";
}

.picto-device-laptop::before {
  content: "\f185";
}

.picto-device-mobile-and-laptop::before {
  content: "\f186";
}

.picto-device-mobile-mobile-euro::before {
  content: "\f187";
}

.picto-device-mobile::before {
  content: "\f188";
}

.picto-device-tablet::before {
  content: "\f189";
}

.picto-document-article::before {
  content: "\f18a";
}

.picto-document-document-edit::before {
  content: "\f18b";
}

.picto-document-document-euro::before {
  content: "\f18c";
}

.picto-document-document-pdf::before {
  content: "\f18d";
}

.picto-document-document-search::before {
  content: "\f18e";
}

.picto-emoji-thumb::before {
  content: "\f18f";
}

.picto-hb-products-livret-a::before {
  content: "\f190";
}

.picto-hb-products-livret-dds::before {
  content: "\f191";
}

.picto-hb-products-livret-ep::before {
  content: "\f192";
}

.picto-hb-products-livret-hello-plus::before {
  content: "\f193";
}

.picto-hb-products-livret-jeune::before {
  content: "\f194";
}

.picto-health-doctor::before {
  content: "\f195";
}

.picto-health-glasses::before {
  content: "\f196";
}

.picto-health-health-mutual::before {
  content: "\f197";
}

.picto-health-heart::before {
  content: "\f198";
}

.picto-health-pharmacy::before {
  content: "\f199";
}

.picto-insurance-car::before {
  content: "\f19a";
}

.picto-insurance-home::before {
  content: "\f19b";
}

.picto-insurance-umbrella::before {
  content: "\f19c";
}

.picto-multimedia-microphone::before {
  content: "\f19d";
}

.picto-multimedia-music-note::before {
  content: "\f19e";
}

.picto-nature-animal-footprint::before {
  content: "\f19f";
}

.picto-nature-earth::before {
  content: "\f1a0";
}

.picto-nature-fire::before {
  content: "\f1a1";
}

.picto-nature-nymphea::before {
  content: "\f1a2";
}

.picto-nature-partly-cloud-day::before {
  content: "\f1a3";
}

.picto-object-basket::before {
  content: "\f1a4";
}

.picto-object-bell::before {
  content: "\f1a5";
}

.picto-object-bib-euro::before {
  content: "\f1a6";
}

.picto-object-book-open::before {
  content: "\f1a7";
}

.picto-object-briefcase-default::before {
  content: "\f1a8";
}

.picto-object-briefcase-euro::before {
  content: "\f1a9";
}

.picto-object-buoy::before {
  content: "\f1aa";
}

.picto-object-candy::before {
  content: "\f1ab";
}

.picto-object-gift::before {
  content: "\f1ac";
}

.picto-object-key-euro::before {
  content: "\f1ad";
}

.picto-object-maintenance-paint-roller::before {
  content: "\f1ae";
}

.picto-object-mallet-euro::before {
  content: "\f1af";
}

.picto-object-rocking-horse::before {
  content: "\f1b0";
}

.picto-object-safety-box::before {
  content: "\f1b1";
}

.picto-object-scale::before {
  content: "\f1b2";
}

.picto-object-shopping-bag-percentage::before {
  content: "\f1b3";
}

.picto-object-shopping-bag::before {
  content: "\f1b4";
}

.picto-object-shopping-cart::before {
  content: "\f1b5";
}

.picto-object-stroller::before {
  content: "\f1b6";
}

.picto-person-character-children::before {
  content: "\f1b7";
}

.picto-person-character-hand-car::before {
  content: "\f1b8";
}

.picto-person-character-insurance::before {
  content: "\f1b9";
}

.picto-person-hand-euro::before {
  content: "\f1ba";
}

.picto-person-hand-heart::before {
  content: "\f1bb";
}

.picto-person-marianne::before {
  content: "\f1bc";
}

.picto-person-profile-alternative::before {
  content: "\f1bd";
}

.picto-person-profile-euro::before {
  content: "\f1be";
}

.picto-person-profile-group::before {
  content: "\f1bf";
}

.picto-security-lock-closed::before {
  content: "\f1c0";
}

.picto-security-lock-open::before {
  content: "\f1c1";
}

.picto-security-mobile-locked::before {
  content: "\f1c2";
}

.picto-security-mobile-shielded::before {
  content: "\f1c3";
}

.picto-shape-diagram::before {
  content: "\f1c4";
}

.picto-state-help-outline::before {
  content: "\f1c5";
}

.picto-state-information-outline::before {
  content: "\f1c6";
}

.picto-state-pending::before {
  content: "\f1c7";
}

.picto-state-success-alternative::before {
  content: "\f1c8";
}

.picto-time-calendar-percentage::before {
  content: "\f1c9";
}

.picto-time-clock-euro::before {
  content: "\f1ca";
}

.picto-transport-bike::before {
  content: "\f1cb";
}

.picto-transport-bus::before {
  content: "\f1cc";
}

.picto-transport-car::before {
  content: "\f1cd";
}

.picto-transport-gas-pump::before {
  content: "\f1ce";
}

.picto-transport-motorcycle::before {
  content: "\f1cf";
}

.picto-transport-parking::before {
  content: "\f1d0";
}

.picto-transport-plane-ticket::before {
  content: "\f1d1";
}

.picto-transport-scooter::before {
  content: "\f1d2";
}

.picto-transport-toll::before {
  content: "\f1d3";
}

.picto-transport-trailer::before {
  content: "\f1d4";
}

.picto-contact-help-outline::before {
  content: "\f1d5";
}

.picto-social-media-facebook::before {
  content: "\f1d6";
}

.picto-social-media-instagram::before {
  content: "\f1d7";
}

.picto-social-media-x::before {
  content: "\f1d8";
}

.picto-social-media-youtube::before {
  content: "\f1d9";
}

@media (min-width: 1024px) {
  .mobile-only,
  .tablette-only {
    display: none !important;
  }
}
.is-hidden {
  display: none;
}

.fs-md {
  font-size: var(--fs-md) !important;
}

.fs-sm {
  font-size: var(--fs-sm) !important;
}

@media print {
  ._comp_aem_layout_header,
  ._comp_aem_layout_sidebar,
  ._comp_aem_layout_footer {
    display: none;
  }
}
.neo-hb-page {
  --default-theme-bg: var(--theme-bg, #fff);
  --desktop-subgrid-width: calc(
    1180 / 1440 * 100%
  );
  /* stylelint-disable */
  /* stylelint-enable */
}
.neo-hb-page[data-theme=secondary], .neo-hb-page[data-theme=light] {
  --theme-bg: var(--color-sky-blue);
}
.neo-hb-page[data-theme=light] .hide-light {
  display: none;
}
.neo-hb-page .container {
  --p-inline: var(--space-16);
  max-width: 100%;
  padding-inline: var(--p-inline);
}
.neo-hb-page .container.container--main {
  padding-inline: 0;
}
@media (min-width: 1024px) {
  .neo-hb-page .container {
    --p-inline: var(--space-32);
    max-width: 90rem;
  }
}
.neo-hb-page .sub-container {
  width: 100%;
  padding-inline: var(--space-16);
}
@media (min-width: 768px) {
  .neo-hb-page .sub-container {
    padding-inline: calc((100% - 1180 / 1440 * 100%) / 2);
  }
}
.neo-hb-page .grid-row {
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .neo-hb-page .grid-row {
    flex-wrap: inherit;
  }
}
@media (min-width: 1024px) {
  .neo-hb-page .neo-hb-main {
    min-height: 80vh;
  }
}

h1,
.h1 {
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-blue-1100);
  --title-size: 2rem;
  --title-line: 1.25;
}
@media (max-width: 1023px) {
  h1,
  .h1 {
    --title-size: 1.75rem;
  }
}

h2,
.h2 {
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-neutral-1400);
  --title-size: 1.5rem;
  --title-line: 1.35;
  margin-top: var(--space-20);
}

.h2-variant {
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-neutral-1400);
  --title-size: 1.5rem;
  --title-line: 1.35;
  margin-top: var(--space-20);
}
@media (max-width: 1023px) {
  .h2-variant {
    --title-size: 1.125rem;
  }
}

.h--dash {
  --dash-color: var(--color-blue-800);
  position: relative;
  padding-top: var(--space-20);
}
.h--dash::before {
  content: "";
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--dash-color);
  display: inline-block;
  position: absolute;
  top: 0;
}
.h--dash.h--center::before {
  left: 50%;
  transform: translateX(-50%);
}

.h--center {
  text-align: center;
}

h3,
.h3 {
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-blue-1100);
  --title-size: 1.125rem;
  --title-line: 1.35;
}

h4,
.h4 {
  --title-color: var(--color-blue-1100);
  --title-size: 1rem;
  --title-line: 1rem;
  font-weight: 500;
  font-size: var(--title-size);
  line-height: var(--title-line);
  color: var(--title-color);
  --title-color: var(--color-neutral-1400);
  --title-line: 1.25;
  text-transform: uppercase;
}

.heading-brand {
  --title-color: var(--color-brand-900);
}

.heading-reverse {
  --title-color: #fff;
}

p {
  line-height: 1.67;
}

.text-primary {
  --text-color: var(--color-blue-1100);
  --text-size: 1rem;
  --text-line: 0.75rem;
  font-size: var(--text-size);
  line-height: var(--text-line);
  color: var(--text-color);
  --text-size: 1rem;
  --text-line: 1.5rem;
}

.text-secondary {
  --text-color: var(--color-blue-1100);
  --text-size: 1rem;
  --text-line: 0.75rem;
  font-size: var(--text-size);
  line-height: var(--text-line);
  color: var(--text-color);
  --text-size: 0.875rem;
  --text-line: 1.25rem;
}

.text-caption {
  --text-color: var(--color-blue-1100);
  --text-size: 1rem;
  --text-line: 0.75rem;
  font-size: var(--text-size);
  line-height: var(--text-line);
  color: var(--text-color);
  --text-size: 0.75rem;
  --text-line: 1rem;
}

.text-caption-xxs {
  --text-color: var(--color-blue-1100);
  --text-size: 1rem;
  --text-line: 0.75rem;
  font-size: var(--text-size);
  line-height: var(--text-line);
  color: var(--text-color);
  --text-size: 0.625rem;
  --text-line: 0.75rem;
}

.text-reverse {
  --text-color: #fff;
}

.text-brand {
  --text-color: var(--color-brand-900);
}

.text-grey {
  --text-color: var(--color-neutral-1000);
}

.violet {
  --text-color: var(--color-coral-700);
}

.error {
  font-style: normal;
  color: var(--color-error);
  font-size: var(--fs-md);
}

a.cta-primary {
  text-decoration: none;
}

.cta,
.btn {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: var(--color-dark-blue);
  --cta-color: #fff;
  --cta-bg-hover: #012d3d;
  --cta-bg-disabled: #aad1df;
}
.cta:hover,
.btn:hover {
  background-color: var(--cta-bg-hover);
}
.cta:active,
.btn:active {
  background-color: var(--cta-bg-active);
}
.cta[disabled],
.btn[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.cta-regular {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: var(--color-dark-blue);
  --cta-color: #fff;
  --cta-bg-hover: #012d3d;
  --cta-bg-disabled: #aad1df;
}
.cta-regular:hover {
  background-color: var(--cta-bg-hover);
}
.cta-regular:active {
  background-color: var(--cta-bg-active);
}
.cta-regular[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.cta-primary,
.btn-primary {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: var(--color-dark-blue);
  --cta-color: #fff;
  --cta-bg-hover: #012d3d;
  --cta-bg-disabled: #aad1df;
}
.cta-primary:hover,
.btn-primary:hover {
  background-color: var(--cta-bg-hover);
}
.cta-primary:active,
.btn-primary:active {
  background-color: var(--cta-bg-active);
}
.cta-primary[disabled],
.btn-primary[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.cta-secondary,
.btn-secondary {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: #11a0b6;
  --cta-bg-active: #0f798a;
  --cta-bg-disabled: #c7f6fd;
  --cta-color: #fff;
}
.cta-secondary:hover,
.btn-secondary:hover {
  background-color: var(--cta-bg-hover);
}
.cta-secondary:active,
.btn-secondary:active {
  background-color: var(--cta-bg-active);
}
.cta-secondary[disabled],
.btn-secondary[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.cta-tertiary {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: var(--color-coral-700);
  --cta-bg-hover: #9e1f2e;
  --cta-bg-active: #7c131f;
  --cta-bg-disabled: #ffb3b5;
  --cta-color: #fff;
}
.cta-tertiary:hover {
  background-color: var(--cta-bg-hover);
}
.cta-tertiary:active {
  background-color: var(--cta-bg-active);
}
.cta-tertiary[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.cta-primary-inverted {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 23px;
  --picto-size: 24px;
  --cta-bg: #fff;
  --cta-color: var(--color-dark-blue);
  --cta-bg-hover: var(--color-neutral-300);
  --cta-bg-active: #e3e6e9;
  --cta-bg-disabled: var(--color-neutral-200);
  --cta-color-disabled: #969da4;
  --cta-color-border: var(--color-neutral-600);
  --cta-color-border-disabled: var(--cta-color-disabled);
  border: 1px solid var(--cta-color-border);
}
.cta-primary-inverted:hover {
  background-color: var(--cta-bg-hover);
}
.cta-primary-inverted:active {
  background-color: var(--cta-bg-active);
}
.cta-primary-inverted[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}
.cta-primary-inverted[disabled] {
  border-color: var(--cta-color-border-disabled);
}

.cta-small {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-radius: 10px;
  --cta-size: 12px;
  --picto-size: 20px;
  padding: 8px 12px;
  --cta-bg: var(--color-dark-blue);
  --cta-color: #fff;
  --cta-bg-hover: #012d3d;
  --cta-bg-disabled: #aad1df;
}
.cta-small.cta-secondary {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: #11a0b6;
  --cta-bg-active: #0f798a;
  --cta-bg-disabled: #c7f6fd;
  --cta-color: #fff;
}
.cta-small.cta-tertiary {
  --cta-bg: var(--color-coral-700);
  --cta-bg-hover: #9e1f2e;
  --cta-bg-active: #7c131f;
  --cta-bg-disabled: #ffb3b5;
  --cta-color: #fff;
}
.cta-small.cta-primary-inverted {
  --cta-bg: #fff;
  --cta-color: var(--color-dark-blue);
  --cta-bg-hover: var(--color-neutral-300);
  --cta-bg-active: #e3e6e9;
  --cta-bg-disabled: var(--color-neutral-200);
  --cta-color-disabled: #969da4;
  --cta-color-border: var(--color-neutral-600);
  --cta-color-border-disabled: var(--cta-color-disabled);
  border: 1px solid var(--cta-color-border);
  padding-block: 7px;
}
.cta-small.cta-primary-inverted:has(i) {
  padding-block: 5px;
}
.cta-small:hover {
  background-color: var(--cta-bg-hover);
}
.cta-small:active {
  background-color: var(--cta-bg-active);
}
.cta-small[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}
.cta-small i[class^=picto-],
.cta-small i[class*=" picto-"] {
  font-size: 20px;
}
.cta-small:has(i) {
  padding-block: 6px;
}

.cta-link {
  --color-link: var(--color-blue-1100);
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: var(--color-link);
  font-size: 0.875em;
  font-weight: 500;
  transition: var(--transition-cta);
  transition-property: color;
  display: inline-flex;
  align-items: center;
}
.cta-link::after {
  content: "";
  display: block;
  position: absolute;
  height: 1px;
  background-color: var(--color-link);
  bottom: 0;
  width: 100%;
}
.cta-link::before {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}
.cta-link:hover::after {
  content: none;
}

.link-arrow-right {
  --color-link: var(--color-blue-1100);
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: var(--color-link);
  font-size: 0.875em;
  font-weight: 500;
  transition: var(--transition-cta);
  transition-property: color;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.link-arrow-right::after {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f12c";
  font-size: 24px;
  font-size: 20px;
}
.link-arrow-right:hover::after {
  content: none;
}

.link-arrow-left {
  --color-link: var(--color-blue-1100);
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  cursor: pointer;
  position: relative;
  color: var(--color-link);
  font-size: 0.875em;
  font-weight: 500;
  transition: var(--transition-cta);
  transition-property: color;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.link-arrow-left::before {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f12b";
  font-size: 24px;
  font-size: 20px;
}
.link-arrow-left:hover::after {
  content: none;
}

:is(.link-arrow-right, .link-arrow-left) {
  gap: 0.4rem;
}

.cta-unstyled {
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
}

.cta-hub {
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: var(--color-sky-blue);
  --cta-bg-active: var(--cta-bg-hover);
  --cta-bg-disabled: var(--cta-bg-hover);
  --cta-color: #fff;
  --cta-color-disabled: #fff;
  --cta-radius: 18px;
  --cta-size: 14px;
  background-color: var(--cta-bg);
  color: var(--cta-color);
  line-height: 1.35;
  border-radius: var(--cta-radius);
  font-weight: 500;
  border: none;
  padding: 0.875rem var(--space-32);
  cursor: pointer;
  align-self: center;
  font-size: var(--cta-size);
  text-decoration: none;
  transition: var(--transition-cta);
  transition-property: background-color, color;
  display: inline-block;
  text-align: center;
  --cta-bg: var(--color-brand-blue);
  --cta-bg-hover: #11a0b6;
  --cta-bg-active: #0f798a;
  --cta-bg-disabled: #c7f6fd;
  --cta-color: #fff;
  --cta-size: 1rem;
  padding: 2rem 1.5rem;
}
.cta-hub:hover {
  background-color: var(--cta-bg-hover);
}
.cta-hub:active {
  background-color: var(--cta-bg-active);
}
.cta-hub[disabled] {
  --cta-bg: var(--cta-bg-disabled);
  --cta-bg-hover: var(--cta-bg-disabled);
  --cta-bg-active: var(--cta-bg-disabled);
  color: var(--cta-color-disabled);
  cursor: default;
}

.link-discrete {
  color: inherit;
  text-decoration: none;
}

sup .link-discrete {
  display: inline;
  white-space: nowrap;
}

.icon-carte.hello-prime:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-hello-prime.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.hello-prime:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.hello-prime:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.hello-prime:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.hello-prime:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.ic-hb-virtual:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-virtuelle-hello-pime.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.ic-hb-virtual:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.ic-hb-virtual:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.ic-hb-virtual:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.ic-hb-virtual:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.ic-premier:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-premier.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.ic-premier:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.ic-premier:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.ic-premier:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.ic-premier:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.ic-visa:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-visa-classic.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.ic-visa:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.ic-visa:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.ic-visa:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.ic-visa:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.ic-electron:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-electron.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.ic-electron:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.ic-electron:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.ic-electron:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.ic-electron:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.hello-one:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-hello-one.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.hello-one:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.hello-one:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.hello-one:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.hello-one:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}
.icon-carte.ic-cirrus:not(.card) {
  display: inline-block;
  background: url("/content/dam/hellobank-neo-aquisition/icons-cards/cb-hb-cirrus.png") no-repeat;
  background-size: contain;
  background-position: initial;
  width: 68px;
  height: 45px;
}
.icon-carte.ic-cirrus:not(.card).ic-small {
  width: 43px;
  height: 22px;
}
.icon-carte.ic-cirrus:not(.card).ic-big {
  width: 88px;
  height: 60px;
}
.icon-carte.ic-cirrus:not(.card).ic-l {
  width: 174px;
  height: 112px;
}
.icon-carte.ic-cirrus:not(.card).ic-xxl {
  width: 230px;
  height: 145px;
}

.table {
  --table-color: var(--color-blue-1100);
  color: var(--table-color);
  border: 1px solid var(--color-neutral-600);
  font-size: var(--fs-md);
  border-radius: var(--radius-main);
  border-spacing: 0;
  width: 100%;
  text-align: left;
  margin-block-end: var(--space-20);
  table-layout: fixed;
  overflow: hidden;
}
.table thead tr {
  background-color: var(--color-neutral-200);
}
.table thead tr th,
.table thead tr td {
  font-weight: 500;
  padding: var(--space-16) var(--space-24);
}
.table tbody tr td,
.table tbody tr th {
  padding: var(--space-24);
  border-top: 1px solid var(--color-neutral-600);
}
.table tbody tr td:first-child,
.table tbody tr th:first-child {
  font-weight: 500;
}

.neo-alert {
  margin-block-end: var(--space-16);
  --_fs-alert: var(--fs-alert, var(--fs-md));
  --_alert-border-width: var(--alert-border-width, 1px);
  --_alert-color: var(--alert-color, var(--color-blue-1100));
  --_alert-background: var(--alert-background, var(--color-neutral-200));
  --_alert-border-color: var(--alert-border-color, var(--color-neutral-500));
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  color: var(--_alert-color);
  border-radius: var(--radius-main);
  border: var(--_alert-border-width) solid var(--_alert-border-color);
  background-color: var(--_alert-background);
  font-size: var(--_fs-alert);
}
.neo-alert::before {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 32px;
}
.neo-alert::before {
  color: var(--color-brand-blue);
}
.neo-alert i {
  font-size: 32px;
}
.neo-alert:is(:not([class*=neo-alert--]), .neo-alert--default) i {
  color: var(--color-brand-blue);
}
.neo-alert:not(.neo-alert--default) a {
  color: inherit;
}
.neo-alert p {
  font-size: 1em;
  line-height: 1.4;
  color: inherit;
}
.neo-alert p > strong {
  font-size: 1.1em;
}
.neo-alert--warning {
  --alert-color: var(--color-orange-1000);
  --alert-background: var(--color-orange-100);
  --alert-border-color: var(--color-orange-500);
}
.neo-alert--error {
  --alert-color: var(--color-coral-900);
  --alert-background: var(--color-coral-100);
  --alert-border-color: var(--color-coral-500);
}

.pill {
  --pill-color: #fff;
  --pill-bg: var(--color-neutral-500);
  color: var(--pill-color);
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.6;
  background-color: var(--pill-bg);
  padding: var(--space-4) var(--space-8);
  border-radius: 12px;
  display: inline-block;
}
.pill--success {
  --pill-bg: var(--color-brand-blue);
}
.pill--warning {
  --pill-bg: var(--color-orange-600);
}
.pill--error {
  --pill-bg: var(--color-coral-700);
}
.pill--pending {
  --pill-bg: var(--color-light-grey);
}

[id^=id_balise_div] iframe,
[id^=nextoutils] iframe {
  width: 100%;
  border: 0;
  height: 100%;
  min-height: 800px;
}

.rte h1 {
  margin-block-end: var(--space-56);
}
.rte h2 {
  margin-block-end: var(--space-8);
}
.rte h3 {
  margin-block-end: var(--space-8);
}
.rte h4 {
  margin-block-end: var(--space-8);
}
.rte h5 {
  margin-block-end: var(--space-8);
}
.rte p {
  margin-block-end: var(--space-16);
  font-size: var(--fs-md);
  line-height: 1.43;
}
.rte a {
  color: var(--color-blue-1100);
  font-weight: 500;
}
.rte table {
  --table-color: var(--color-blue-1100);
  color: var(--table-color);
  border: 1px solid var(--color-neutral-600);
  font-size: var(--fs-md);
  border-radius: var(--radius-main);
  border-spacing: 0;
  width: 100%;
  text-align: left;
  margin-block-end: var(--space-20);
  table-layout: fixed;
  overflow: hidden;
}
.rte table thead tr {
  background-color: var(--color-neutral-200);
}
.rte table thead tr th,
.rte table thead tr td {
  font-weight: 500;
  padding: var(--space-16) var(--space-24);
}
.rte table tbody tr td,
.rte table tbody tr th {
  padding: var(--space-24);
  border-top: 1px solid var(--color-neutral-600);
}
.rte table tbody tr td:first-child,
.rte table tbody tr th:first-child {
  font-weight: 500;
}
.rte table tr th {
  border: 0;
}
.rte table tr td {
  border: 0;
}
.rte table tr:first-of-type th {
  border-top: 0;
}
.rte table tr:first-of-type td {
  border-top: 0;
}
.rte ul {
  list-style-type: disc;
  font-size: var(--fs-md);
  margin-left: var(--space-16);
  color: var(--text-color);
}
.rte ul li {
  margin-block-end: var(--space-20);
  color: var(--color-blue-1100);
}

.tooltip-cta {
  --cta-size: 24px;
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  max-height: var(--cta-size);
  max-width: var(--cta-size);
  min-height: var(--cta-size);
  padding: 1px;
  display: inline-block;
  text-wrap: nowrap;
  color: var(--color-neutral-1200);
  line-height: 1;
}
.tooltip-cta::before {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f125";
  font-size: 16px;
  padding-right: 8px;
  vertical-align: top;
}

.tooltip {
  --tooltip-background: var(--color-neutral-300);
  --tooltip-color: var(--color-neutral-1200);
  --arrow-size: 0.8rem;
  position: absolute;
  top: 0;
  width: 18.75rem;
  padding: 1rem;
  font-size: var(--fs-sm);
  font-weight: normal;
  color: var(--tooltip-color);
  background-color: var(--tooltip-background);
  border-radius: calc(var(--radius-main) / 2);
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.12);
  z-index: var(--z-tooltip);
  display: none;
}
.tooltip .arrow {
  display: none;
}
.tooltip p {
  font-size: inherit;
  color: inherit;
  line-height: 1.4;
}
.tooltip .arrow {
  display: block;
  position: absolute;
  width: var(--arrow-size);
  height: var(--arrow-size);
  top: calc(var(--arrow-size) / 2 * -1);
  transform: rotate(45deg);
  background-color: var(--tooltip-background);
  z-index: var(--z-tooltip);
}

.ul--check li {
  position: relative;
  line-height: 1.5;
  text-align: left;
  padding-inline-start: 2.2rem;
  font-size: inherit;
  color: inherit;
  margin-bottom: var(--space-16);
}
.ul--check li::before {
  position: absolute;
  left: 0;
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f126";
  font-size: 24px;
  color: var(--color-brand-900);
}